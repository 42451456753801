import { useState, useEffect } from "react";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getCookie } from "../../../_cookies";
import axios from "axios";
import { ADM_API } from "../../../_variables";
import LoadingSm from "../../LoadingSm";

const EditUser = ({ id }) => {
  const es = {
    modTitle: "Editar usuario",
    modCan: "Cancelar",
    modSuc: "Guardar cambios",
    modInf: "Información del usuario",
    modEmail: "Correo electrónico",
    modName: "Nombre completo",
    modStreet: "Dirección",
    modCity: "Ciudad y Código Postal",
    modBankTitle: "Información bancaria del usuario",
    modBank: "Información bancaria",
    modAccNum: "Número de cuenta",
    modRoutNum: "Número de ruta",
    modSwiftCode: "Código Swift",
  };
  const en = {
    modTitle: "Edit user",
    modCan: "Cancel",
    modSuc: "Save changes",
    modInf: "Customer information",
    modEmail: "Email address",
    modName: "Full name",
    modStreet: "Address",
    modCity: "City & Zip line",
    modBankTitle: "User's bank information",
    modBank: "Bank information",
    modAccNum: "Account number",
    modRoutNum: "Routing number",
    modSwiftCode: "Swift Code",
  };

  const [txt, setTxt] = useState(es);
  const [mail, setMail] = useState("");
  const [name, setName] = useState("");
  const [load, setLoad] = useState(false);
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("")
  const [bankInfo, setBankInfo] = useState("");
  const [accNum, setAccNum] = useState("");
  const [routNum, setRoutNum] = useState("");
  const [swiftCode, setSwiftCode] = useState("");

  const getUserInfo = () => {
    const formData = new FormData();
    formData.append("cookie", getCookie());
    formData.append("id", id);

    axios
      .post(`${ADM_API}/get_Customers_by_ID`, formData)
      .then((res) => {
        if (res.status === 200 && res.data.status === "ok") {
          setMail(res.data.result[0].mail);
          setName(res.data.result[0].name);
          setBankInfo(res.data.result[0].bank_inf);
          setAccNum(res.data.result[0].acc_number);
          setRoutNum(res.data.result[0].routing_num);
          setSwiftCode(res.data.result[0].swift_code);

          const resAddress = res.data.result[0].address.split("-", 2);
          setStreet(resAddress[0]);
          setCity(resAddress[1])
        }
      })
      .catch(() => {});
  };

  const editUser = () => {
    setLoad(true);

    const formData = new FormData();
    formData.append("cookie", getCookie());
    formData.append("id", id);
    formData.append("name", name);
    formData.append("email", mail);
    formData.append("address", `${street}-${city}`)
    formData.append("bank_inf", bankInfo)
    formData.append("acc_number", accNum)
    formData.append("routing_num", routNum)
    formData.append("swift_code", swiftCode)

    axios
      .post(`${ADM_API}/update_customers`, formData)
      .then(() => {
        setLoad(false);
        setName("");
        setMail("");
        window.location.reload();
      })
      .catch(() => {
        setLoad(false);
        setName("");
        setMail("");
        window.location.reload();
      });
  };

  useEffect(() => {
    getUserInfo();

    if (localStorage.getItem("lang") === "en") {
      setTxt(en);
    } else {
      setTxt(es);
    }
  }, []);

  return (
    <>
      <span
        data-bs-target={`#editUserModal${id}`}
        data-bs-toggle="modal"
        role="button"
        aria-label={txt.modTitle}
      >
        <button
          className="btn btn-secondary me-3"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          data-bs-html="true"
          title={txt.modTitle}
        >
          <FontAwesomeIcon icon={faPen} />
        </button>
      </span>

      {/* <!-- Modal --> */}
      <div
        className="modal fade"
        id={`editUserModal${id}`}
        tabIndex="-1"
        aria-labelledby={`editUserModalLabel${id}`}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title fw-bold"
                id={`editUserModalLabel${id}`}
              >
                {txt.modTitle}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body text-start">
              <p className="text-dark fw-bold mb-3">{txt.modInf}</p>
              <div className="form-floating mb-3">
                <input
                  type="email"
                  className="form-control"
                  id="floatingInput"
                  value={mail}
                  onChange={(e) => setMail(e.target.value)}
                  placeholder={txt.modEmail}
                />
                <label htmlFor="floatingInput">{txt.modEmail}</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="floatingPassword"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder={txt.modName}
                />
                <label htmlFor="floatingPassword">{txt.modName}</label>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      id={`street-${id}`}
                      className="form-control"
                      value={street}
                      onChange={(e) => setStreet(e.target.value)}
                      placeholder={txt.modStreet}
                    />
                    <label htmlFor={`street-${id}`}>{txt.modStreet}</label>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      id={`city-${id}`}
                      className="form-control"
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                      placeholder={txt.modCity}
                    />
                    <label htmlFor={`city-${id}`}>{txt.modCity}</label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <p className="text-dark fw-bold mb-3">{txt.modBankTitle}</p>
                </div>
                <div className="col-12">
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      id={`bankInfo-${id}`}
                      className="form-control"
                      value={bankInfo}
                      onChange={(e) => setBankInfo(e.target.value)}
                      placeholder={txt.modBank}
                    />
                    <label htmlFor={`bankInfo-${id}`}>{txt.modBank}</label>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      id={`accountNumber-${id}`}
                      className="form-control"
                      value={accNum}
                      onChange={(e) => setAccNum(e.target.value)}
                      placeholder={txt.modAccNum}
                    />
                    <label htmlFor={`accountNumber-${id}`}>{txt.modAccNum}</label>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      id={`routingNumber-${id}`}
                      className="form-control"
                      value={routNum}
                      onChange={(e) => setRoutNum(e.target.value)}
                      placeholder={txt.modRoutNum}
                    />
                    <label htmlFor={`routingNumber-${id}`}>{txt.modRoutNum}</label>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      id={`swiftCode-${id}`}
                      className="form-control"
                      value={swiftCode}
                      onChange={(e) => setSwiftCode(e.target.value)}
                      placeholder={txt.modSwiftCode}
                    />
                    <label htmlFor={`swiftCode-${id}`}>{txt.modSwiftCode}</label>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                {txt.modCan}
              </button>
              <button
                type="button"
                className="btn btn-primary text-white"
                onClick={() => editUser()}
              >
                {load ? <LoadingSm /> : txt.modSuc}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditUser;
