const TableRow = ({ concepto, valor }) => {
  return (
    <tr>
      <td>{concepto}</td>
      <td>{valor}</td>
    </tr>
  );
};

export default TableRow;
