import { useState, useEffect } from "react";
import AddTransaction from "../../components/admin/transacciones/AddTransaction";
import Layout from "../../components/Layout";
import { getCookie } from "../../_cookies";
import axios from "axios";
import { ADM_API } from "../../_variables";
import TransaccionesTable from "../../components/admin/transacciones/TransaccionesTable";
import { Navigate } from "react-router-dom";

const AdminTransacciones = () => {
  const es = {
    title: "Transacciones",
  };
  const en = {
    title: "Transactions",
  };

  const [txt, setTxt] = useState(es);
  const [users, setUsers] = useState([]);

  const getUsersList = () => {
    const formData = new FormData();
    formData.append("cookie", getCookie());

    axios
      .post(`${ADM_API}/get_Customers`, formData)
      .then((res) => {
        if (res.status === 200 && res.data.status === "ok") {
          setUsers(res.data.result);
        }
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (localStorage.getItem("lang") === "en") {
      setTxt(en);
    } else {
      setTxt(es);
    }

    getUsersList();
  }, []);

  return (
    <Layout>
      {!getCookie() ? (
        <Navigate to="/login" replace />
      ) : (
        <div className="container py-5">
          <h1 className="text-primary fw-bold mb-5">{txt.title}</h1>

          <div className="row">
            <div className="col-12 text-end mb-3">
              <AddTransaction id="" users={users} />
            </div>
            <div className="col-12 mb-3">
              <TransaccionesTable users={users} />
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default AdminTransacciones;
