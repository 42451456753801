import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { text } from "@fortawesome/fontawesome-svg-core";
import { getCookie } from "../../../_cookies";
import axios from "axios";
import { ADM_API } from "../../../_variables";
import {
  getCurrencyFromFormat,
  getNumberFromFormat,
} from "../../../js/parseNumber";

const AddTransaction = ({ id, users }) => {
  const es = {
    modTitle: "Nueva transacción",
    modCan: "Cancelar",
    modSuc: "Agregar",
    trCustomer: "Cliente",
    trCliId: "Id del Cliente",
    trAccType: "Tipo de Cuenta",
    trTranId: "Id de la transacción",
    trTranType: "Tipo de transacción",
    trAtto: "Abogado",
    trClieAccNum: "Número de Cuenta del Cliente",
    trIntBank: "Banco Intermediario",
    trTransSta: "Estado de la Transacción",
    trReiSta: "Estado de la Reinversión",
    trAccBal: "Balance de Cuenta",
    trFundSta: "Estado de los Fondos",
    trPendObl: "Obligaciones Pendientes",
    trDisburDate: "Fecha de Desembolso",
  };
  const en = {
    modTitle: "New transaction",
    modCan: "Cancel",
    modSuc: "Add transaction",
    trCustomer: "Customer",
    trCliId: "Client Id",
    trAccType: "Account Type",
    trTranId: "Transaction Id",
    trTranType: "Transaction Type",
    trAtto: "Attorney",
    trClieAccNum: "Client Account Number",
    trIntBank: "Intermediary Bank",
    trTransSta: "Transaction Status",
    trReiSta: "Reinbursement Status",
    trAccBal: "Account Balance",
    trFundSta: "Funds Status",
    trPendObl: "Pending Obligation",
    trDisburDate: "Disbursement Date",
  };

  const [txt, setTxt] = useState(es);
  const [trCustomer, settrCustomer] = useState("");
  const [trCliId, settrCliId] = useState("");
  const [trAccType, settrAccType] = useState("");
  const [trTranId, settrTranId] = useState("");
  const [trTranType, settrTranType] = useState("");
  const [trAtto, settrAtto] = useState("");
  const [trClieAccNum, settrClieAccNum] = useState("");
  const [trIntBank, settrIntBank] = useState("");
  const [trTransSta, settrTransSta] = useState("No Status");
  const [trReiSta, settrReiSta] = useState("No Status");
  const [trAccBal, settrAccBal] = useState("");
  const [trFundSta, settrFundSta] = useState("");
  const [trPendObl, settrPendObl] = useState("");
  const [trDisburDate, settrDisburDate] = useState("");

  const nuevaTransaccion = () => {
    if (!trCustomer) {
      alert("Por favor seleccione un usuario");
      return 0;
    }
    if (!trAccBal) {
      alert("Por favor ingrese un balance de cuenta");
      return 0;
    }
    if (!trPendObl) {
      alert(
        "Por favor ingrese las obligaciones pendientes, en caso de no aplicar por favor ingrese '0'"
      );
      return 0;
    }

    const formData = new FormData();
    formData.append("cookie", getCookie());
    formData.append("customer", trCustomer);
    formData.append("client_id", trCliId);
    formData.append("account_type", trAccType);
    formData.append("transaction_id", trTranId);
    formData.append("transaction_type", trTranType);
    formData.append("attorney", trAtto);
    formData.append("client_account_number", trClieAccNum);
    formData.append("intermediary_bank", trIntBank);
    formData.append("transaction_status", trTransSta);
    formData.append("reinbursement_status", trReiSta);
    formData.append(
      "account_balance",
      getNumberFromFormat(trAccBal) ? getNumberFromFormat(trAccBal) : 0
    );
    formData.append("funds_status", trFundSta);
    formData.append(
      "currency",
      getCurrencyFromFormat(trAccBal)
        ? getCurrencyFromFormat(trAccBal)
        : getCurrencyFromFormat(trPendObl)
        ? getCurrencyFromFormat(trPendObl)
        : "USD"
    );
    formData.append(
      "pending_obligation",
      getNumberFromFormat(trPendObl) ? getNumberFromFormat(trPendObl) : 0
    );
    formData.append("disinbursement_date", trDisburDate);

    axios
      .post(`${ADM_API}/new_Transactions`, formData)
      .then(() => {
        window.location.reload();
      })
      .catch(() => {
        window.location.reload();
      });
  };

  const getBankInfoFromUser = (userId) => {
    const params = new FormData();
    params.append("cookie", getCookie());
    params.append("id", userId);

    axios
      .post(`${ADM_API}/get_Customers_by_ID`, params)
      .then((res) => {
        if (res.status === 200 && res.data.status === 'ok') {
          settrClieAccNum(res.data.result[0].acc_number)
          settrIntBank(res.data.result[0].bank_inf)
        }
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (localStorage.getItem("lang") === "en") {
      setTxt(en);
    } else {
      setTxt(es);
    }
  }, []);

  return (
    <>
      <span
        data-bs-target={
          id ? `#newTransactionModal${id}` : "#newTransactionModal"
        }
        data-bs-toggle="modal"
        role="button"
        aria-label={txt.modTitle}
      >
        <button
          className={`btn me-3 ${
            id ? "btn-success text-white" : "btn-primary text-white"
          }`}
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          data-bs-html="true"
          title={txt.modTitle}
        >
          <FontAwesomeIcon icon={faPlus} /> {!id && txt.modTitle}
        </button>
      </span>

      <div
        className="modal fade"
        id={id ? `newTransactionModal${id}` : "newTransactionModal"}
        tabIndex="-1"
        aria-labelledby={
          id ? `#newTransactionModalLabel${id}` : "newTransactionModalLabel"
        }
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title fw-bold"
                id={
                  id
                    ? `#newTransactionModalLabel${id}`
                    : "newTransactionModalLabel"
                }
              >
                {txt.modTitle}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body text-start">
              <div className="form-floating mb-3">
                <select
                  className="form-select"
                  id={`trName${id}`}
                  aria-label={txt.trCustomer}
                  onChange={(e) => {
                    settrCustomer(e.target.value);
                    getBankInfoFromUser(e.target.value);
                  }}
                  defaultValue="DEFAULT"
                >
                  <option value="DEFAULT" disabled>
                    Selecciona el usuario
                  </option>
                  {users &&
                    users.map((user) => (
                      <option key={user.id} value={user.id}>
                        {user.id} - {user.name}
                      </option>
                    ))}
                </select>
                <label htmlFor={`trName${id}`}>{txt.trCustomer}</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  id={`trClientId${id}`}
                  placeholder={txt.trCliId}
                  value={trCliId}
                  onChange={(e) => settrCliId(e.target.value)}
                />
                <label htmlFor={`trClientId${id}`}>{txt.trCliId}</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  id={`trAccType${id}`}
                  placeholder={txt.trAccType}
                  value={trAccType}
                  onChange={(e) => settrAccType(e.target.value)}
                />
                <label htmlFor={`trAccType${id}`}>{txt.trAccType}</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  id={`trTransId${id}`}
                  placeholder={txt.trTranId}
                  value={trTranId}
                  onChange={(e) => settrTranId(e.target.value)}
                />
                <label htmlFor={`trTransId${id}`}>{txt.trTranId}</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  id={`trTranType${id}`}
                  placeholder={txt.trTranType}
                  value={trTranType}
                  onChange={(e) => settrTranType(e.target.value)}
                />
                <label htmlFor={`trTranType${id}`}>{txt.trTranType}</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  id={`trAttor${id}`}
                  placeholder={txt.trAtto}
                  value={trAtto}
                  onChange={(e) => settrAtto(e.target.value)}
                />
                <label htmlFor={`trAttor${id}`}>{txt.trAtto}</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  id={`trCliAccNum${id}`}
                  placeholder={txt.trClieAccNum}
                  value={trClieAccNum}
                  onChange={(e) => settrClieAccNum(e.target.value)}
                />
                <label htmlFor={`trCliAccNum${id}`}>{txt.trClieAccNum}</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  id={`trBank${id}`}
                  placeholder={txt.trIntBank}
                  value={trIntBank}
                  onChange={(e) => settrIntBank(e.target.value)}
                />
                <label htmlFor={`trBank${id}`}>{txt.trIntBank}</label>
              </div>
              <div className="form-floating mb-3">
                <select
                  className="form-select"
                  id={`trTranStatus${id}`}
                  aria-label={txt.trTransSta}
                  onChange={(e) => settrTransSta(e.target.value)}
                  defaultValue="No Status"
                >
                  <option value="No Status">No Status</option>
                  <option value="Up To Date">Up To Date</option>
                  <option value="Pending Payment">Pending Payment</option>
                  <option value="Canceled">Canceled</option>
                </select>
                <label htmlFor={`trTranStatus${id}`}>{txt.trTransSta}</label>
              </div>
              <div className="form-floating mb-3">
                <select
                  className="form-select"
                  id={`trReinStatus${id}`}
                  aria-label={txt.trReiSta}
                  onChange={(e) => settrReiSta(e.target.value)}
                  defaultValue="No Status"
                >
                  <option value="No Status">No Status</option>
                  <option value="Up To Date">Up To Date</option>
                  <option value="Pending Payment">Pending Payment</option>
                  <option value="Canceled">Canceled</option>
                </select>
                <label htmlFor={`trReinStatus${id}`}>{txt.trReiSta}</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  id={`trAccBal${id}`}
                  placeholder={txt.trAccBal}
                  value={trAccBal}
                  onChange={(e) => settrAccBal(e.target.value)}
                />
                <label htmlFor={`trAccBal${id}`}>{txt.trAccBal}</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  id={`trFundSta${id}`}
                  placeholder={txt.trFundSta}
                  value={trFundSta}
                  onChange={(e) => settrFundSta(e.target.value)}
                />
                <label htmlFor={`trFundSta${id}`}>{txt.trFundSta}</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  id={`trPendOb${id}`}
                  placeholder={txt.trPendObl}
                  value={trPendObl}
                  onChange={(e) => settrPendObl(e.target.value)}
                />
                <label htmlFor={`trPendOb${id}`}>{txt.trPendObl}</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  id={`trDisDate${id}`}
                  placeholder={txt.trDisburDate}
                  value={trDisburDate}
                  onChange={(e) => settrDisburDate(e.target.value)}
                />
                <label htmlFor={`trDisDate${id}`}>{txt.trDisburDate}</label>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
              >
                {txt.modCan}
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => nuevaTransaccion()}
              >
                {txt.modSuc}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddTransaction;
