import axios from "axios";
import { useState, useEffect, useRef } from "react";
import { Navigate } from "react-router-dom";
import Layout from "../components/Layout";
import RegisterUser from "../components/user/login/RegisterUser";
import { getCookie, setCookie } from "../_cookies";
import { ADM_API, API_URL } from "../_variables";
import Swal from "sweetalert2";

const Login = () => {
  const emailInput = useRef(null);
  const passInput = useRef(null);

  const es = {
    title: "Inicia sesión",
    emailInput: "Correo electrónico",
    passInput: "Contraseña",
    errorLogin: "El correo electrónico o la contraseña no son correctos",
    btnErrorLogin: "Aceptar",
  };
  const en = {
    title: "Log In",
    emailInput: "Email Address",
    passInput: "Password",
    errorLogin: "The email or password is incorrect",
    btnErrorLogin: "Accept",
  };
  const [txt, setTxt] = useState(es);
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [load, setLoad] = useState(false);
  const [admin, setAdmin] = useState(false);
  const [normal, setNormal] = useState(false);

  const isAdmin = () => {
    const param = new FormData();
    param.append("cookie", getCookie());

    axios
      .post(`${ADM_API}/isAdmin`, param)
      .then((res) => {
        if (res.data.status === "ok" && res.data.result) {
          setAdmin(true);
        }
        if (res.data.status === "error" || !res.data.result) {
          setNormal(true);
        }
      })
      .catch(() => {});
  };

  useEffect(() => {
    isAdmin();
    if (localStorage.getItem("lang") === "en") {
      setTxt(en);
    } else {
      setTxt(es);
    }
  }, []);

  const handleSubmit = (e) => {
    setLoad(true);
    e.preventDefault();
    if (!email) {
      emailInput.current.classList.add("is-invalid");
      setLoad(false);
      return null;
    }
    if (!pass) {
      passInput.current.classList.add("is-invalid");
      setLoad(false);
      return null;
    }
    if (email && pass) {
      const loginData = new FormData();
      loginData.append("mail", email);
      loginData.append("password", pass);

      axios
        .post(`${API_URL}/user_login`, loginData)
        .then((res) => {
          if (res.status === 200 && res.data.status === "ok") {
            setCookie("userData", res.data.result, 30);
            setLoad(false);
            window.location.reload();
          } else {
            setLoad(false);
            handleError();
          }
        })
        .catch(() => {
          setLoad(false);
          alert("Error");
        });
    }
  };

  const handleError = () => {
    Swal.fire({
      title: "Error",
      text: txt.errorLogin,
      icon: "error",
      confirmButtonColor: "#3085d6",
      confirmButtonText: txt.btnErrorLogin,
    });
  };

  return (
    <Layout>
      {admin && <Navigate to="/admin/dashboard" replace />}
      {normal && <Navigate to="/user/dashboard" replace />}
      <div className="container py-5">
        <div className="row">
          <div className="col-12 col-md-6 offset-md-3">
            <form
              onSubmit={(e) => handleSubmit(e)}
              className="rounded shadow-sm border p-3"
            >
              <h2 className="text-primary fw-bold mb-3">{txt.title}</h2>
              <div className="form-floating mb-3">
                <input
                  type="email"
                  className="form-control"
                  ref={emailInput}
                  id="floatingInput"
                  autoComplete="on"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    emailInput.current.classList.remove("is-invalid");
                  }}
                  placeholder={txt.emailInput}
                />
                <label htmlFor="floatingInput">{txt.emailInput}</label>
              </div>
              <div className="form-floating">
                <input
                  type="password"
                  className="form-control"
                  ref={passInput}
                  id="floatingPassword"
                  autoComplete="on"
                  value={pass}
                  onChange={(e) => {
                    setPass(e.target.value);
                    passInput.current.classList.remove("is-invalid");
                  }}
                  placeholder={txt.passInput}
                />
                <label htmlFor="floatingPassword">{txt.passInput}</label>
              </div>
              <div className="mb-3">
                <RegisterUser />
              </div>
              <div className="text-end mt-3">
                <button
                  className="btn btn-primary px-3 text-white"
                  aria-label={txt.title}
                  type="submit"
                >
                  {load ? (
                    <div
                      className="spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  ) : (
                    txt.title
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Login;
