import { useState, useEffect } from "react";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getCookie } from "../../../_cookies";
import axios from "axios";
import { ADM_API } from "../../../_variables";

const ChangePass = ({ id }) => {
  const es = {
    modTitle: "Cambiar contraseña",
    modCan: "Cancelar",
    modSuc: "Cambiar contraseña",
    inPass: "Nueva contraseña",
    alrt: "Por favor escribe la nueva contraseña antes de enviar",
  };
  const en = {
    modTitle: "Change Password",
    modCan: "Cancel",
    modSuc: "Change Password",
    inPass: "New password",
    alrt: "Please write new password before send",
  };

  const [txt, setTxt] = useState(es);
  const [pass, setPass] = useState("");

  const changePassword = () => {
    if (pass) {
      const formData = new FormData();
      formData.append("cookie", getCookie());
      formData.append("id", id);
      formData.append("password", pass);

      axios
        .post(`${ADM_API}/update_customers_password`, formData)
        .then(() => {
          setPass("");
        })
        .catch(() => {
          setPass("");
        });
    } else {
      alert(txt.alrt);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("lang") === "en") {
      setTxt(en);
    } else {
      setTxt(es);
    }
  }, []);

  return (
    <>
      <span
        data-bs-target={`#changePassModal${id}`}
        data-bs-toggle="modal"
        role="button"
        aria-label={txt.modTitle}
      >
        <button
          className="btn btn-info text-white me-3"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          data-bs-html="true"
          title={txt.modTitle}
        >
          <FontAwesomeIcon icon={faCircleExclamation} />
        </button>
      </span>

      {/* Modal */}
      <div
        className="modal fade"
        id={`changePassModal${id}`}
        tabIndex="-1"
        aria-labelledby={`changePassModalLabel${id}`}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title fw-bold"
                id={`changePassModalLabel${id}`}
              >
                {txt.modTitle}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body text-start">
              <div className="form-floating">
                <input
                  type="password"
                  className="form-control"
                  id={`newPasswordInput${id}`}
                  placeholder={txt.inPass}
                  value={pass}
                  onChange={(e) => setPass(e.target.value)}
                />
                <label htmlFor={`newPasswordInput${id}`}>{txt.inPass}</label>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-outline-danger"
                data-bs-dismiss="modal"
              >
                {txt.modCan}
              </button>
              <button
                type="button"
                data-bs-dismiss="modal"
                className="btn btn-secondary"
                onClick={() => changePassword()}
              >
                {txt.modSuc}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePass;
