// Dependencias
import { useState, useEffect, useRef } from "react";
import { getCookie } from "../../../_cookies";
import axios from "axios";
import { API_URL } from "../../../_variables";
import { setCookie } from "../../../_cookies";

// Main
const RegisterUser = () => {
  const emailInput = useRef(null);
  const passInput = useRef(null);
  const nameInput = useRef(null);
  const streetInput = useRef(null)
  const cityZipInput = useState(null)
  const bankInput = useRef(null);
  const accInput = useRef(null);
  const routInput = useRef(null);
  const swiftInput = useRef(null);
  const phoneInput = useRef(null);
  const phone2Input = useRef(null);

  const es = {
    btn: "¿No tienes cuenta? ¡Regístrate!",
    modCan: "Cancelar",
    modNext: "Continuar",
    modBack: "Regresar",
    modSuc: "Registrarme",
    modInfo: "Tu información",
    modAddress: "Tu Dirección",
    modPass: "Elige tu contraseña",
    modAcc: "Información bancaria",
    emailIn: "Correo electrónico",
    passIn: "Contraseña",
    nameIn: "Nombre completo",
    streetIn: "Dirección",
    cityZipIn: "Ciudad y Código Postal",
    bankIn: "Información bancaria",
    accIn: "Número de cuenta",
    routIn: "Número de ruta",
    swiftIn: "Código Swift",
    phoneIn: "Teléfono de Casa",
    phone2In: "Teléfono móvil",
    alertInputs: "Por favor asegúrate de llenar todos los campos",
    alertEmail: "Se ha enviado un correo de verificación.",
  };
  const en = {
    btn: "You do not have an account? Sign up!",
    modCan: "Cancel",
    modNext: "Continue",
    modBack: "Go back",
    modSuc: "Sign Up",
    modInfo: "Your information",
    modAddress: "Your address",
    modPass: "Choose your password",
    modAcc: "Bank Information",
    emailIn: "Email address",
    passIn: "Password",
    nameIn: "Full Name",
    streetIn: "Address",
    cityZipIn: "City & Zip Code",
    bankIn: "Bank Information",
    accIn: "Account Number",
    routIn: "Routing Number",
    swiftIn: "Swift Code",
    phoneIn: "Home Phone Number",
    phone2In: "Mobile Phone Number",
    alertInputs: "Please make sure you complete all the inputs",
    alertEmail: "A confirmation email has been sent",
  };

  const [txt, setTxt] = useState(es);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [address, setAddress] = useState("")
  const [cityZip, setCityZip] = useState("")
  const [bank, setBank] = useState("");
  const [acc, setAcc] = useState("");
  const [rout, setRout] = useState("");
  const [swift, setSwift] = useState("");
  const [phone, setPhone] = useState("");
  const [phone2, setPhone2] = useState("");
  const [load, setLoad] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("lang") === "en") {
      setTxt(en);
    } else {
      setTxt(es);
    }
  }, []);

  const handleNewUser = () => {
    setLoad(true);
    if (!name) {
      nameInput.current.classList.add("is-invalid");
    }
    if (!email) {
      emailInput.current.classList.add("is-invalid");
    }
    if (!pass) {
      passInput.current.classList.add("is-invalid");
    }
    if (!address) {
      streetInput.current.classList.add("is-invalid")
    }
    if (!cityZip) {
      cityZipInput.current.classList.add("is-invalid")
    }
    if (!phone2) {
      phone2Input.current.classList.add('is-invalid')
    }
    if (name && email && pass && address && cityZip && phone2) {
      addNewUser(name, email, pass, address, cityZip);
    } else {
      alert(txt.alertInputs)
    }
    setLoad(false);
  };

  const addNewUser = (nName, nEmail, nPass, nAddress, nCityZip) => {
    setLoad(true);
    const formData = new FormData();
    // formData.append("cookie", getCookie());
    formData.append("name", nName);
    formData.append("email", nEmail);
    formData.append("password", nPass);
    formData.append("address", `${nAddress}-${nCityZip}`);
    formData.append("bank_inf", bank);
    formData.append("acc_number", acc);
    formData.append("routing_num", rout);
    formData.append("swift_code", swift);
    formData.append("phone_home", phone)
    formData.append("phone_cell", phone2) 

    axios
      .post(`${API_URL}/user_register`, formData)
      .then((res) => {
        setLoad(false);
        if (res.data.email === 'Email envíado') alert(txt.alertEmail)
        window.location.reload()
      })
      .catch((err) => {
        setLoad(false);
        console.log('Error en registro: ', err)
      });
  };

  return (
    <>
      <button
        className="btn btn-link text-dark"
        data-bs-toggle="modal"
        data-bs-target="#registerUserModal"
      >
        {txt.btn}
      </button>

      {/* Modal */}
      <div
        className="modal fade"
        id="registerUserModal"
        tabIndex="-1"
        aria-labelledby="registerUserModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title fw-bold" id="registerUserModalLabel">
                {txt.btn}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body text-start">
              <p className="text-dark fw-bold mb-3">{txt.modInfo}</p>

              <form>
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    id="floatingName"
                    className="form-control"
                    ref={nameInput}
                    autoComplete="on"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                      nameInput.current.classList.remove("is-invalid");
                    }}
                    placeholder={txt.nameIn}
                  />
                  <label htmlFor="floatingName">{txt.nameIn}</label>
                </div>
                <div className="form-floating mb-3">
                  <input
                    type="email"
                    className="form-control"
                    ref={emailInput}
                    id="floatingInput"
                    autoComplete="on"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      emailInput.current.classList.remove("is-invalid");
                    }}
                    placeholder={txt.emailIn}
                  />
                  <label htmlFor="floatingInput">{txt.emailIn}</label>
                </div>
                <div className="form-floating mb-3">
                  <input
                    type="number"
                    className="form-control"
                    ref={phoneInput}
                    id="registerFormInput"
                    autoComplete="on"
                    value={phone}
                    onChange={(e) => {
                      setPhone(e.target.value);
                      phoneInput.current.classList.remove("is-invalid");
                    }}
                    placeholder={txt.phoneIn}
                  />
                  <label htmlFor="registerFormInput">{txt.phoneIn}</label>
                </div>
                <div className="form-floating mb-3">
                  <input
                    type="number"
                    className="form-control"
                    ref={phone2Input}
                    id="registerPhone2Input"
                    autoComplete="on"
                    value={phone2}
                    onChange={(e) => {
                      setPhone2(e.target.value);
                      phone2Input.current.classList.remove("is-invalid");
                    }}
                    placeholder={txt.phone2In}
                  />
                  <label htmlFor="registerPhone2Input">{txt.phone2In}</label>
                </div>
                <div className="mb-3">
                  <p className="text-dark fw-bold mb-3">{txt.modAddress}</p>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        id="registerStreet"
                        className="form-control"
                        autoComplete="on"
                        placeholder={txt.streetIn}
                        ref={streetInput}
                        value={address}
                        onChange={(e) => {
                          setAddress(e.target.value);
                          streetInput.current.classList.remove("is-invalid");
                        }}
                      />
                      <label htmlFor="registerStreet">{txt.streetIn}</label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        id="registerNE"
                        className="form-control"
                        autoComplete="on"
                        placeholder={txt.cityZipIn}
                        ref={cityZipInput}
                        value={cityZip}
                        onChange={(e) => {
                          setCityZip(e.target.value);
                          cityZipInput.current.classList.remove("is-invalid");
                        }}
                      />
                      <label htmlFor="registerNE">{txt.cityZipIn}</label>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-outline-danger"
                data-bs-dismiss="modal"
              >
                {txt.modCan}
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-target="#registerUserModal2"
                data-bs-toggle="modal"
                // onClick={() => handleNewUser()}
              >
                {load ? (
                  <div
                    className="spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  txt.modNext
                )}
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Modal */}
      <div
        className="modal fade"
        id="registerUserModal2"
        tabIndex="-1"
        aria-labelledby="registerUserModal2Label"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title fw-bold" id="registerUserModal2Label">
                {txt.btn}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body text-start">
              <p className="text-dark fw-bold mb-3">{txt.modAcc}</p>
              <div className="row">
                <div className="col-12 mb-3">
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      id="registerBank"
                      className="form-control"
                      autoComplete="on"
                      placeholder={txt.bankIn}
                      ref={bankInput}
                      value={bank}
                      onChange={(e) => {
                        setBank(e.target.value);
                        bankInput.current.classList.remove("is-invalid");
                      }}
                    />
                    <label htmlFor="registerBank">{txt.bankIn}</label>
                  </div>
                </div>
                <div className="col-12 mb-3">
                <div className="form-floating mb-3">
                    <input
                      type="text"
                      id="registerAcc"
                      className="form-control"
                      autoComplete="on"
                      placeholder={txt.accIn}
                      ref={accInput}
                      value={acc}
                      onChange={(e) => {
                        setAcc(e.target.value);
                        accInput.current.classList.remove("is-invalid");
                      }}
                    />
                    <label htmlFor="registerAcc">{txt.accIn}</label>
                  </div>
                </div>
                <div className="col-12 mb-3">
                <div className="form-floating mb-3">
                    <input
                      type="text"
                      id="registerRout"
                      className="form-control"
                      autoComplete="on"
                      placeholder={txt.routIn}
                      ref={routInput}
                      value={rout}
                      onChange={(e) => {
                        setRout(e.target.value);
                        routInput.current.classList.remove("is-invalid");
                      }}
                    />
                    <label htmlFor="registerRout">{txt.routIn}</label>
                  </div>
                </div>
                <div className="col-12 mb-3">
                <div className="form-floating mb-3">
                    <input
                      type="text"
                      id="registerSwift"
                      className="form-control"
                      autoComplete="on"
                      placeholder={txt.swiftIn}
                      ref={swiftInput}
                      value={swift}
                      onChange={(e) => {
                        setSwift(e.target.value);
                        swiftInput.current.classList.remove("is-invalid");
                      }}
                    />
                    <label htmlFor="registerSwift">{txt.swiftIn}</label>
                  </div>
                </div>
              </div>

              <p className="text-dark fw-bold mb-3">{txt.modPass}</p>
              <div className="row">
                <div className="col-12">
                  <div className="form-floating mb-3">
                    <input
                      type="password"
                      className="form-control"
                      ref={passInput}
                      id="floatingPassword"
                      autoComplete="on"
                      value={pass}
                      onChange={(e) => {
                        setPass(e.target.value);
                        passInput.current.classList.remove("is-invalid");
                      }}
                      placeholder={txt.passIn}
                    />
                    <label htmlFor="floatingPassword">{txt.passIn}</label>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-outline-danger"
                data-bs-dismiss="modal"
              >
                {txt.modCan}
              </button>
              <button
                type="button"
                className="btn btn-outline-secondary"
                data-bs-target="#registerUserModal"
                data-bs-toggle="modal"
              >
                {txt.modBack}
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => handleNewUser()}
              >
                {load ? (
                  <div
                    className="spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  txt.modSuc
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegisterUser;
