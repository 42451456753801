const formatedNumber = (num) => {
  return new Intl.NumberFormat('es-MX', {
    minimumFractionDigits: 2
  }).format(Math.round(((parseFloat(num)) + Number.EPSILON) * 100) / 100)
}

const currencyNumber = (num, currency) => {
  return new Intl.NumberFormat("es-MX", { style: "currency", currency: currency }).format(num)
}

const getNumberFromFormat = (num) => {
  if (num.includes(".")) {
    return parseFloat(num.replace(/[^ 0-9]/g, "") / 100);
  } else {
    return parseFloat(num.replace(/[^ 0-9]/g, ""));
  }
};

const getCurrencyFromFormat = (num) => {
  const currency = num.split(" ", 2)[1]
  
  if (currency) {
    return currency.toUpperCase();
  }

  return "USD"
};

export {
  formatedNumber, currencyNumber, getNumberFromFormat, getCurrencyFromFormat
}