// Dependencies
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../_variables";

// Components
import LoadingSm from "../components/LoadingSm";
import Layout from "./../components/Layout";

// Main
const Validate = () => {
  const [searchParams] = useSearchParams();

  const es = {
    title: "Validando tu información",
    successAlert: "Usuario validado, inicia sesión para terminar el proceso.",
    errorAlert: "No fue posible validar tu usuario. Por favor intenta nuevamente.",
  };
  const en = {
    title: "Validating your information",
    successAlert: "Validated user, log in to finish the process.",
    errorAlert: "It was not possible to validate your username. Please try again.",
  };

  const [txt, setTxt] = useState(es);

  function validateAccount() {
    const c = searchParams.get("c");

    if (c) {
      const formData = new FormData();
      formData.append('code', c);

      axios
        .post(`${API_URL}/user_register_validate`, formData)
        .then((res) => {
          if (res.status === 200 && res.data.status === 'ok' && res.data.result === 'user validated') {
            alert(txt.successAlert)
            window.location.replace(window.location.origin + '/login')
          } else {
            alert(txt.errorAlert)
            window.location.replace(window.location.origin + '/login')
          }
        })
        .catch(() => alert(txt.errorAlert));
    }
  }

  useEffect(() => {
    if (localStorage.getItem("lang") === "en") {
      setTxt(en);
    } else {
      setTxt(es);
    }

    validateAccount()
  }, []);

  return (
    <Layout>
      <div className="container py-5">
        <div className="row">
          <div className="col-12 d-flex justify-content-center align-items-center flex-column">
            <h1 className="text-primary text-center fw-bold mb-4">
              {txt.title}
            </h1>
            <LoadingSm />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Validate;
