import { useState, useEffect } from "react";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getCookie } from "../../../_cookies";
import axios from "axios";
import { ADM_API } from "../../../_variables";

const DelUser = ({ id }) => {
  const es = {
    modTitle: "Eliminar cliente",
    modCan: "Cancelar",
    modSuc: "Eliminar",
    warning: `¿Estás seguro de eliminar el cliente con ID: ${id}?`,
  };
  const en = {
    modTitle: "Delete customer",
    modCan: "Cancel",
    modSuc: "Delete",
    warning: `Are you sure about delete the customer with ID: ${id}?`,
  };

  const [txt, setTxt] = useState(es);

  const deleteUser = () => {
    const formData = new FormData();
    formData.append("cookie", getCookie());
    formData.append("id", id);

    axios
      .post(`${ADM_API}/delete_Customers_by_ID`, formData)
      .then(() => {
        window.location.reload();
      })
      .catch(() => {
        window.location.reload();
      });
  };

  useEffect(() => {
    if (localStorage.getItem("lang") === "en") {
      setTxt(en);
    } else {
      setTxt(es);
    }
  }, []);

  return (
    <>
      <span
        data-bs-target={`#delUserModal${id}`}
        data-bs-toggle="modal"
        role="button"
        aria-label={txt.modTitle}
      >
        <button
          className="btn btn-danger text-white me-3"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          data-bs-html="true"
          title={txt.modTitle}
        >
          <FontAwesomeIcon icon={faTrash} />
        </button>
      </span>

      <div
        className="modal fade"
        id={`delUserModal${id}`}
        tabIndex="-1"
        aria-labelledby={`delUserModalLabel${id}`}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title fw-bold" id={`delUserModalLabel${id}`}>
                {txt.modTitle}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body text-start">
              <p className="text-dark fw-bold text-center fs-3">
                {txt.warning}
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-outline-danger"
                onClick={() => deleteUser()}
              >
                {txt.modSuc}
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                {txt.modCan}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DelUser;
