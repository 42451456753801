import axios from "axios";
import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import Layout from "../../components/Layout";
import TableRow from "../../components/user/dashboard/TableRow";
import UpdateMyInfo from "../../components/user/dashboard/UpdateMyInfo";
import UserDocs from "../../components/user/dashboard/UserDocs";
import { formatedNumber } from "../../js/parseNumber";
import { getCookie, setCookie } from "../../_cookies";
import { API_URL } from "../../_variables";
import Swal from "sweetalert2";
import { update_my_password } from "../../services";

const UserDashboard = () => {
  const es = {
    pageTitle: "Tablero",
    infoEmail: "Correo electrónico",
    infoAddress: "Dirección",
    infoBank: "Información bancaria",
    infoAccNum: "Número de cuenta",
    infoRoutNum: "Número de routing",
    infoSwiftCode: "Código Swift",
    transTitle: "Transacciones",
    transSub: "Transacción",
    transMyId: "Mi Id",
    transAccType: "Tipo de cuenta",
    transType: "Tipo de transacción",
    transId: "ID de Transacción",
    transAtto: "Abogado",
    transAccBal: "Balance de cuenta",
    transState: "Estado",
    transOblPend: "Obligaciones pendientes",
    transReleaseDate: "Fecha de liberación",
    myDocsTitle: "Mis documentos",
    btnEliminar: "Eliminar Cuenta",
    btnConfirm: "¿Estás seguro?",
    btnConfirmText:
      "Se eliminará esta cuenta de usuario en esta plataforma pero tu cuenta con Garcia Ibarra y Abogados no se cerrará",
    btnConfirmYes: "Sí, eliminar",
    btnConfirmNo: "No, cancelar",
    btnDeleteSuccess: "¡Cuenta eliminada!",
    btnChanePass: "Cambiar contraseña",
    txtChangePass: "Escriba su nueva contraseña",
    btnConfirmPass: "Cambiar contraseña",
    btnCancelPasss: "Cancelar",
    txtChangePassSuccess: "¡Contraseña cambiada!",
    imputConfirmPass: "Confirmar contraseña",
    errorPass: "Las contraseñas no coinciden, por favor intente de nuevo.",
  };
  const en = {
    pageTitle: "Dashboard",
    infoEmail: "Email",
    infoAddress: "Address",
    infoBank: "Bank information",
    infoAccNum: "Account number",
    infoRoutNum: "Routing number",
    infoSwiftCode: "Swift Code",
    transTitle: "Transactions",
    transSub: "Transaction",
    transMyId: "My Id",
    transAccType: "Account Type",
    transType: "Transaction Type",
    transId: "Transaction Id",
    transAtto: "Attorney",
    transAccBal: "Account balance",
    transState: "Status",
    transOblPend: "Pending obligations",
    transReleaseDate: "Release Date",
    myDocsTitle: "My documents",
    btnEliminar: "Delete Account",
    btnConfirm: "Are you sure?",
    btnConfirmText:
      "This user account will be deleted on this platform but your account with Garcia Ibarra y Abogados will not be closed!",
    btnConfirmYes: "Yes, delete it!",
    btnConfirmNo: "No, cancel!",
    btnDeleteSuccess: "Your account has been deleted!!",
    btnChanePass: "Change Password",
    txtChangePass: "Write your new password",
    btnConfirmPass: "Change Password",
    btnCancelPasss: "Cancel",
    txtChangePassSuccess: "Your password has been changed!",
    imputConfirmPass: "Confirm Password",
    errorPass: "Passwords do not match, please try again.",
  };

  const [txt, setTxt] = useState(es);
  const [trans, setTrans] = useState([]);
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(false);

  const getMyTransactions = () => {
    setLoading(true);
    const params = new FormData();
    params.append("cookie", getCookie());

    axios
      .post(`${API_URL}/get__my_Transactions`, params)
      .then((res) => {
        if ((res.status === 200) & (res.data.status === "ok")) {
          setTrans(res.data.result);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const getMyData = () => {
    setLoading(true);
    const params = new FormData();
    params.append("cookie", getCookie());

    axios
      .post(`${API_URL}/get_my_data`, params)
      .then((res) => {
        if ((res.status === 200) & (res.data.status === "ok")) {
          setUser(res.data.result[0]);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (localStorage.getItem("lang") === "en") {
      setTxt(en);
    } else {
      setTxt(es);
    }

    getMyTransactions();
    getMyData();
  }, []);

  const handleDelete = () => {
    Swal.fire({
      title: txt.btnConfirm,
      text: txt.btnConfirmText,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      confirmButtonText: txt.btnConfirmYes,
    }).then((result) => {
      if (result.isConfirmed) {
        const params = new FormData();
        params.append("cookie", getCookie());

        axios
          .post(`${API_URL}/close_my_account`, params)
          .then((res) => {
            if ((res.status === 200) & (res.data.status === "ok")) {
              setCookie("userData", "", -1);
              window.location.href = "/login";
            }
          })
          .catch(() => {});
      }
    });
  };

  const handleChangePass = async () => {
    let password;

    Swal.fire({
      title: txt.txtChangePass,
      html: `
        <input type="password" id="password" class="swal2-input">
        <input type="password" id="confirm-password" class="swal2-input" placeholder="Confirmar contraseña">
      `,
      focusConfirm: false,
      showCancelButton: true,
      confirmButtonText: txt.btnConfirmPass,
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        password = document.getElementById("password").value;
        const confirmPassword =
          document.getElementById("confirm-password").value;
        if (password !== confirmPassword) {
          Swal.showValidationMessage(txt.errorPass);
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
      inputValidator: (value) => {
        if (!value) {
          return "Debes escribir la contraseña!";
        }
      },
    }).then(async (result) => {
      if (result.value) {
        const response = await update_my_password(password);
        if (!response) {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: `Request failed`,
          });
        } else {
          setCookie("userData", response.data.result, 30);
          Swal.fire({
            icon: "success",
            title: txt.txtChangePassSuccess,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    });
  };
  return (
    <Layout>
      {!getCookie() ? (
        <Navigate to="/login" replace />
      ) : (
        <div className="container py-5">
          <div className="d-flex justify-content-between">
            <h1 className="text-primary fw-bold">{txt.pageTitle}</h1>
            <button className="btn btn-danger h-50 " onClick={handleDelete}>
              {txt.btnEliminar}
            </button>
          </div>
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-warning h-50 mb-2"
              onClick={handleChangePass}
            >
              {txt.btnChanePass}
            </button>
          </div>
          <div className="row mb-5">
            {user && (
              <div className="col-12 rounded-3 border shadow-sm p-3">
                <div className="row">
                  <div className="col-12 col-md-4 col-lg-3 mb-3">
                    {user.id && (
                      <div className="ratio ratio-1x1 rounded-circle mb-3 overflow-hidden text-center">
                        <img
                          src={`https://api.garcia-ibarra-asociados.com/assets/files/userfiles/${user.id}/main.png`}
                          alt=""
                          className="img-fluid object-cover"
                        />
                      </div>
                    )}
                    <h2 className="text-primary fw-bold text-center">
                      {user.name}
                    </h2>
                  </div>
                  <div className="col-12 col-md-8 col-lg-9 mb-3">
                    {user.id && !loading && (
                      <UpdateMyInfo
                        id={user.id}
                        name={user.name}
                        email={user.mail}
                        address={user.address}
                        bank={user.bank_inf}
                        accountNumber={user.acc_number}
                        routingNumber={user.routing_num}
                        swiftCode={user.swift_code}
                      />
                    )}
                    {user.id && !loading && (
                      <div className="row overflow-hidden">
                        <div className="col-6">
                          <p className="fw-bold">{txt.infoEmail}: </p>
                        </div>
                        <div className="col-6">
                          <p className="text-break">{user.mail}</p>
                        </div>
                        <div className="col-6">
                          <p className="fw-bold">{txt.infoAddress}: </p>
                        </div>
                        <div className="col-6">
                          <p>{user.address.replace("-", ". ")}</p>
                        </div>
                        <div className="col-6">
                          <p className="fw-bold">{txt.infoBank}: </p>
                        </div>
                        <div className="col-6">
                          <p>{user.bank_inf}</p>
                        </div>
                        <div className="col-6">
                          <p className="fw-bold">{txt.infoAccNum}: </p>
                        </div>
                        <div className="col-6">
                          <p>{user.acc_number}</p>
                        </div>
                        <div className="col-6">
                          <p className="fw-bold">{txt.infoRoutNum}: </p>
                        </div>
                        <div className="col-6">
                          <p>{user.routing_num}</p>
                        </div>
                        <div className="col-6">
                          <p className="fw-bold">{txt.infoSwiftCode}: </p>
                        </div>
                        <div className="col-6">
                          <p>{user.swift_code}</p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="row mb-5">
            <h2 className="text-dark">{txt.transTitle}</h2>
            {trans.length > 0 &&
              trans.map((tr) => (
                <div className="col-12 col-md-6 col-lg-4 p-2" key={tr.id}>
                  <div className="rounded-3 shadow-sm border overflow-hidden">
                    <table className="table table-striped table-hover align-middle text-center">
                      <thead>
                        <tr>
                          <th className="text-center" colSpan="2">
                            {txt.transSub} {tr.transaction_id}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <TableRow
                          concepto={txt.transMyId}
                          valor={tr.client_id}
                        />
                        <TableRow
                          concepto={txt.transAccType}
                          valor={tr.account_type}
                        />
                        <TableRow
                          concepto={txt.transId}
                          valor={tr.transaction_id}
                        />
                        <TableRow
                          concepto={txt.transType}
                          valor={tr.transaction_type}
                        />
                        <TableRow
                          concepto={txt.transAtto}
                          valor={tr.attorney}
                        />
                        <TableRow
                          concepto={txt.transAccBal}
                          valor={`$ ${formatedNumber(tr.account_balance)}`}
                        />
                        <TableRow
                          concepto={txt.transState}
                          valor={tr.transaction_status}
                        />
                        <TableRow
                          concepto={txt.transOblPend}
                          valor={`$ ${formatedNumber(tr.pending_obligation)}`}
                        />
                        <TableRow
                          concepto={txt.transReleaseDate}
                          valor={tr.disinbursement_date}
                        />
                      </tbody>
                    </table>
                  </div>
                </div>
              ))}
          </div>
          <div className="row">
            <div className="col-12">
              <h2 className="text-dark mb-3">{txt.myDocsTitle}</h2>
              {user && <UserDocs userId={user.id} />}
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default UserDashboard;
