import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AdminDashboard from './pages/admin/Dashboard';
import Documentos from './pages/admin/Documentos';
import AdminTransacciones from './pages/admin/Transacciones';
import AdminUsers from './pages/admin/Usuarios';
import Home from './pages/Home';
import Login from './pages/Login';
import Test from './pages/Test';
import UserDashboard from './pages/user/UserDashboard';
import Validate from './pages/Validate';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />}/>
        <Route path="/login" element={<Login />}/>
        <Route path="/validate" element={<Validate />}/>
        <Route path="/admin/dashboard" element={<AdminDashboard />} />
        <Route path="/admin/usuarios" element={<AdminUsers />} />
        <Route path="/admin/transacciones" element={<AdminTransacciones />} />
        <Route path="/admin/documentos" element={<Documentos />} />

        <Route path="/user/dashboard" element={<UserDashboard />} />

        <Route path="*" element={<Login />}/>
        <Route path="/test" element={<Test />}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
