import axios from "axios";
import { API_URL } from "../_variables";
import { getCookie } from "../_cookies";



export const update_my_password = async (password) => {
    const response = await axios.post(
        `${API_URL}/update_my_password`, null, {
            params :{
                cookie: getCookie(),
                password: password
            }
        }
    );
    if ((response.status === 200) & (response.data.status === "ok")) {
        return response
    } else {
        alert("Email no enviado");
        return null;
    }
}
    
