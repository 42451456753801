import { useState, useEffect } from "react";
import { getCookie } from "../../../_cookies";
import axios from "axios";
import { ADM_API } from "../../../_variables";

const NewDoc = ({ users, trans }) => {
  const es = {
    btnTitle: "Subir un documento",
    modTitle: "Subir un documento",
    modUserInput: "Selecciona un usuario",
    modUserOption: "Selecciona el usuario",
    modTransInput: "Selecciona una transacción",
    modTransOption: "Selecciona la transacción",
    modUserBtn: "Subir por usuario",
    modTransBtn: "Subir por transacción",
  }
  const en = {
    btnTitle: "Upload a document",
    modTitle: "Upload a document",
    modUserInput: "Select user",
    modUserOption: "Select user",
    modTransInput: "Select transaction",
    modTransOption: "Select transaction",
    modUserBtn: "Upload by user",
    modTransBtn: "Upload by Transaction",
  }

  const [txt, setTxt] = useState(es)
  const [user, setUser] = useState("");
  const [transVal, setTransVal] = useState("");
  const [method, setMethod] = useState("");

  const uploadFiles = (e) => {
    if (method === "user" && user) {
      const parametros = new FormData(document.getElementById("fileForm"));
      e.preventDefault();

      // const formData = new FormData();
      parametros.append("cookie", getCookie());
      parametros.append("ide", user);
      // formData.append("fileToUpload", e.target['fileToUpload'].value);

      axios
        .post(`${ADM_API}/upload_files_to_User`, parametros)
        .then(() => window.location.reload())
        .catch(() => window.location.reload());
    }
    if (method === "trans" && transVal) {
      const parametros = new FormData(document.getElementById("fileForm"));
      e.preventDefault();

      // const formData = new FormData();
      parametros.append("cookie", getCookie());
      parametros.append("ide", transVal);
      // formData.append("fileToUpload", e.target['fileToUpload'].value);

      axios
        .post(`${ADM_API}/upload_files_to_Transaction`, parametros)
        .then(() => window.location.reload())
        .catch(() => window.location.reload());
    }
  };

  useEffect(() => {
    if (localStorage.getItem("lang") === "en") {
      setTxt(en);
    } else {
      setTxt(es);
    }
  }, [])

  return (
    <div className="d-flex align-items-center">
      <button
        className="btn btn-primary text-white"
        data-bs-toggle="modal"
        data-bs-target="#newDocModal"
      >
        {txt.btnTitle}
      </button>

      <div
        className="modal fade"
        id="newDocModal"
        tabIndex="-1"
        aria-labelledby="newDocModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="newDocModalLabel">
                {txt.modTitle}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="form-floating mb-3">
                <select
                  id="addNewDocUser"
                  className="form-control"
                  defaultValue="DEFAULT"
                  onChange={(e) => setUser(e.target.value)}
                >
                  <option value="DEFAULT" disabled>
                    {txt.modUserOption}
                  </option>
                  {users.map((usr) => (
                    <option key={usr.id} value={usr.id}>
                      {usr.id} - {usr.name}
                    </option>
                  ))}
                </select>
                <label htmlFor="addNewDocUser">{txt.modUserInput}</label>
              </div>
              <div className="form-floating mb-3">
                <select
                  id="addNewDocTrans"
                  className="form-control"
                  defaultValue="DEFAULT"
                  onChange={(e) => setTransVal(e.target.value)}
                >
                  <option value="DEFAULT" disabled>
                    {txt.modTransOption}
                  </option>
                  {trans.map((usr) => (
                    <option key={usr.id} value={usr.id}>
                      {usr.id} - {usr.transaction_id} - {usr.account_balance}
                    </option>
                  ))}
                </select>
                <label htmlFor="addNewDocTrans">
                  {txt.modTransInput}
                </label>
              </div>
              <form
                action=""
                onSubmit={(e) => {
                  uploadFiles(e);
                }}
                id="fileForm"
              >
                <input
                  type="file"
                  name="fileToUpload"
                  id="fileToUpload"
                  className="form-control"
                />
                <button
                  onClick={() => setMethod("user")}
                  className="btn btn-secondary my-3 me-3"
                  type="submit"
                >
                  {txt.modUserBtn}
                </button>
                <button
                  onClick={() => setMethod("trans")}
                  className="btn btn-secondary my-3"
                  type="submit"
                >
                  {txt.modTransBtn}
                </button>
              </form>
            </div>
            <div className="modal-footer"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewDoc;
