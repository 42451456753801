import { faFileUpload, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { getCookie } from "../../../_cookies";
import { API_URL } from "../../../_variables";
import axios from "axios";

export default function UploadFile() {
  const es = {
    modalTitle: 'Subir un nuevo documento',
    uploadFileBtn: 'Subir documento',
    cancelBtn: 'Cancelar',
  }
  const en = {
    modalTitle: 'Upload a new document',
    uploadFileBtn: 'Upload',
    cancelBtn: 'Cancel',
  }

  const [txt, setTxt] = useState(es)

  const uploadFiles = (e) => { 
      const parametros = new FormData(document.getElementById("fileForm"));
      e.preventDefault();

      // const formData = new FormData();
      parametros.append("cookie", getCookie());
      // formData.append("fileToUpload", e.target['fileToUpload'].value);

      axios
        .post(`${API_URL}/upload_my_files`, parametros)
        .then(() => window.location.reload())
        .catch(() => window.location.reload());
  };

  useEffect(() => {
    if (localStorage.getItem("lang") === "en") {
      setTxt(en);
    } else {
      setTxt(es);
    }
  }, [])

  return (
    <>
      <button
        className="ratio ratio-1x1 bg-transparent border-0 rounded p-3 h-100"
        style={{ maxWidth: "100px" }}
        data-bs-toggle="modal" 
        data-bs-target="#addNewFileModal"
      >
        <FontAwesomeIcon icon={faFileUpload} size="1x" className="text-muted" />
      </button>

      <div
        className="modal fade"
        id="addNewFileModal"
        tabIndex="-1"
        aria-labelledby="addNewFileModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="addNewFileModalLabel">
                {txt.modalTitle}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
            <form
                action=""
                onSubmit={(e) => {
                  uploadFiles(e);
                }}
                id="fileForm"
              >
                <input
                  type="file"
                  name="fileToUpload"
                  id="fileToUpload"
                  className="form-control"
                />
                <button
                  className="btn btn-secondary my-3 me-3"
                  type="submit"
                >
                  {txt.uploadFileBtn}
                </button>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-outline-danger"
                data-bs-dismiss="modal"
              >
                {txt.cancelBtn}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
