// Dependencias
import axios from "axios";
import React, { useState, useEffect } from "react";
import { getCookie } from "../../../_cookies";
import { ADM_API } from "../../../_variables";
import DelUser from "./DelUser";
import EditUser from "./EditUser";
import ChangePass from "./ChangePass";

// Main
const UserTable = () => {
  const es = {
    tableCaption: "Lista de usuarios",
    tableId: "Id",
    tableName: "Nombre",
    tableEmail: "Correo",
    tableAction: "Acciones",
    labelNTrans: "Nueva transacción",
  };
  const en = {
    tableCaption: "Users list",
    tableId: "Id",
    tableName: "Name",
    tableEmail: "Email",
    tableAction: "Actions",
    labelNTrans: "New transaction",
  };

  const [txt, setTxt] = useState(es);
  const [users, setUsers] = useState([]);
  const [load, setLoad] = useState(false);

  const getUsersList = () => {
    setLoad(true);

    const formData = new FormData();
    formData.append("cookie", getCookie());

    axios
      .post(`${ADM_API}/get_Customers`, formData)
      .then((res) => {
        if (res.status === 200 && res.data.status === "ok") {
          setUsers(res.data.result);
          setLoad(false);
        }
      })
      .catch(() => {
        setLoad(false);
      });
  };

  useEffect(() => {
    getUsersList();

    if (localStorage.getItem("lang") === "en") {
      setTxt(en);
    } else {
      setTxt(es);
    }
  }, []);

  return (
    <div className="table-responsive">
      <table className="table table-stripped table-hover align-middle text-center">
        <caption>{txt.tableCaption}</caption>
        <thead>
          <tr>
            <th>{txt.tableId}</th>
            <th>{txt.tableName}</th>
            <th>{txt.tableEmail}</th>
            <th>{txt.tableAction}</th>
          </tr>
        </thead>
        <tbody>
          {load && (
            <>
              <tr>
                <td>
                  <div className="d-flex justify-content-center">
                    <div
                      className="spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="d-flex justify-content-center">
                    <div
                      className="spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="d-flex justify-content-center">
                    <div
                      className="spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="d-flex justify-content-center">
                    <div
                      className="spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                </td>
              </tr>
            </>
          )}
          {users &&
            users.map((user) => (
              <tr key={user.id}>
                <td>{user.id}</td>
                <td>{user.name}</td>
                <td>{user.mail}</td>
                <td>
                  <EditUser id={user.id} />
                  <ChangePass id={user.id} />

                  <DelUser id={user.id} />
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default UserTable;
