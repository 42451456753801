// Dependencias
import { useEffect, useState } from "react";
import axios from "axios";

// Javascript
import { getCookie } from "../../_cookies";
import { ADM_API } from "../../_variables";

// Componentes
import DataCard from "../../components/admin/dashboard/DataCard";
import Layout from "../../components/Layout";
import { formatedNumber } from "../../js/parseNumber";
import { Navigate } from "react-router-dom";

// Main
const AdminDashboard = () => {
  const es = {
    title: "Bienvenido",
    clients: "Clientes",
    inver: "Inversores",
    trans: "Transacciones",
    inv: "Invertidos",
  };
  const en = {
    title: "Welcome",
    clients: "Clients",
    inver: "Investors",
    trans: "Transactions",
    inv: "Inverted",
  };
  const [txt, setTxt] = useState(es);
  const [totalClientes, setTotalClientes] = useState(false);
  const [totalInversores, setTotalInversores] = useState(false);
  const [totalTransacciones, setTotalTransacciones] = useState(false);
  const [totalInvertidoUSD, setTotalInvertidoUSD] = useState(false);
  const [totalInvertidoMXN, setTotalInvertidoMXN] = useState(false);
  const [totalInvertidoCAD, setTotalInvertidoCAD] = useState(false);
  const [load, setLoad] = useState(false);

  const obtenerTotalClientes = () => {
    setLoad(true);
    const formData = new FormData();
    formData.append("cookie", getCookie());

    axios
      .post(`${ADM_API}/get_Customers`, formData)
      .then((res) => {
        if (res.status === 200 && res.data.status === "ok") {
          setTotalClientes(res.data.result.length);
        }
        setLoad(false);
      })
      .catch(() => {
        setLoad(false);
      });
  };

  const obtenerTotalTransacciones = () => {
    setLoad(true);
    const formData = new FormData();
    formData.append("cookie", getCookie());

    axios
      .post(`${ADM_API}/get_Transactions`, formData)
      .then((res) => {
        if (res.status === 200 && res.data.status === "ok") {
          setTotalTransacciones(res.data.result.length);

          const inversores = [];
          let totalInverUSD = 0;
          let totalInverMXN = 0;
          let totalInverCAD = 0;

          res.data.result.forEach((inv) => {
            if (!inversores.includes(inv.customer)) {
              inversores.push(inv.customer);
            }
            if (inv.currency === "MXN") {
              totalInverMXN += parseFloat(inv.account_balance);
            } else if (inv.currency === "CAD") {
              totalInverCAD += parseFloat(inv.account_balance);
            } else {
              totalInverUSD += parseFloat(inv.account_balance);
            }
          });

          setTotalInversores(inversores.length);
          if (totalInverUSD > 0) setTotalInvertidoUSD(totalInverUSD);
          if (totalInverCAD > 0) setTotalInvertidoCAD(totalInverCAD);
          if (totalInverMXN > 0) setTotalInvertidoMXN(totalInverMXN);
        }
        setLoad(false);
      })
      .catch(() => {
        setLoad(false);
      });
  };

  useEffect(() => {
    obtenerTotalClientes();
    obtenerTotalTransacciones();

    if (localStorage.getItem("lang") === "en") {
      setTxt(en);
    } else {
      setTxt(es);
    }
  }, []);

  return (
    <Layout>
      {!getCookie() ? (
        <Navigate to="/login" replace />
      ) : (
        <div className="container py-5">
          <h1 className="text-primary fw-bold mb-4">{txt.title}</h1>
          <div className="row">
            <div className="col-12 col-sm-6 col-lg-3 p-2">
              {load && (
                <div className="placeholder-glow h-100 rounded-3">
                  <div className="placeholder bg-dark w-100 h-100"></div>
                </div>
              )}
              {totalClientes && (
                <DataCard num={totalClientes} txt={txt.clients} />
              )}
            </div>
            <div className="col-12 col-sm-6 col-lg-3 p-2">
              {load && (
                <div className="placeholder-glow h-100 rounded-3">
                  <div className="placeholder bg-dark w-100 h-100"></div>
                </div>
              )}
              {totalInversores && (
                <DataCard num={totalInversores} txt={txt.inver} />
              )}
            </div>
            <div className="col-12 col-sm-6 col-lg-3 p-2">
              {load && (
                <div className="placeholder-glow h-100 rounded-3">
                  <div className="placeholder bg-dark w-100 h-100"></div>
                </div>
              )}
              {totalTransacciones && (
                <DataCard num={totalTransacciones} txt={txt.trans} />
              )}
            </div>
            {load && (
              <div className="col-12 col-sm-6 col-lg-3 p-2">
                <div className="placeholder-glow h-100 rounded-3">
                  <div className="placeholder bg-dark w-100 h-100"></div>
                </div>
              </div>
            )}
            {totalInvertidoUSD && totalInvertidoUSD > 0 && (
              <div className="col-12 col-sm-6 col-lg-3 p-2">
                <DataCard
                  num={`$ ${formatedNumber(totalInvertidoUSD)} USD`}
                  txt={txt.inv}
                />
              </div>
            )}
            {totalInvertidoCAD && totalInvertidoCAD > 0 && (
              <div className="col-12 col-sm-6 col-lg-3 p-2">
                <DataCard
                  num={`$ ${formatedNumber(totalInvertidoCAD)} CAD`}
                  txt={txt.inv}
                />
              </div>
            )}
            {totalInvertidoMXN && totalInvertidoMXN > 0 && (
              <div className="col-12 col-sm-6 col-lg-3 p-2">
                <DataCard
                  num={`$ ${formatedNumber(totalInvertidoMXN)} MXN`}
                  txt={txt.inv}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </Layout>
  );
};

export default AdminDashboard;
