// Dependencias
import { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import AddUser from "../../components/admin/users/AddUser";
import UserTable from "../../components/admin/users/UserTable";

// Componentes
import Layout from "../../components/Layout";
import { getCookie } from "../../_cookies";

// Main
const AdminUsers = () => {
  const es = {
    title: "Usuarios",
  };
  const en = {
    title: "Users",
  };
  const [txt, setTxt] = useState(es);

  useEffect(() => {
    if (localStorage.getItem("lang") === "en") {
      setTxt(en);
    } else {
      setTxt(es);
    }
  }, []);

  return (
    <Layout>
      {!getCookie() ? (
        <Navigate to="/login" replace />
      ) : (
        <div className="container py-5">
          <h1 className="text-primary fw-bold mb-5">{txt.title}</h1>
          <div className="row">
            <div className="col-12 text-end mb-3">
              <AddUser />
            </div>
            <div className="col-12">
              <UserTable />
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default AdminUsers;
