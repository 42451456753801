import { useState, useEffect } from "react";
import { getCookie } from "../../../_cookies";
import { ADM_API } from "../../../_variables";
import axios from "axios";
import { currencyNumber, formatedNumber } from "../../../js/parseNumber";
import EditTransaction from "./EditTransaction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import DelTransaction from "./DelTransaction";

const TransaccionesTable = ({ users }) => {
  const es = {
    caption: "Transacciones",
    client: "Cliente",
    atto: "Abogado",
    status: "Estado",
    balance: "Balance",
    pending: "Pendiente",
    actions: "Acciones",
  }
  const en = {
    caption: "Trasactions",
    client: "Client",
    atto: "Attorney",
    status: "Status",
    balance: "Balance",
    pending: "Pending",
    actions: "Actions",
  }

  const [txt, setTxt] = useState(es)
  const [load, setLoad] = useState(true);
  const [transacciones, setTransacciones] = useState([]);

  const obtenerTotalTransacciones = () => {
    setLoad(true);
    const formData = new FormData();
    formData.append("cookie", getCookie());

    axios
      .post(`${ADM_API}/get_Transactions`, formData)
      .then((res) => {
        if (res.status === 200 && res.data.status === "ok") {
          setTransacciones(res.data.result);
          setLoad(false);
        }
        setLoad(false);
      })
      .catch(() => {
        setLoad(false);
      });
  };

  useEffect(() => {
    if (localStorage.getItem("lang") === "en") {
      setTxt(en);
    } else {
      setTxt(es);
    }

    obtenerTotalTransacciones();
  }, []);

  return (
    <div className="table-responsive">
      <table className="table table-stripped table-hover align-middle text-center">
        <caption>{txt.caption}</caption>
        <thead>
          <tr>
            <th>Id</th>
            <th>{txt.client}</th>
            <th>{txt.atto}</th>
            <th>{txt.status}</th>
            <th>{txt.balance}</th>
            <th>{txt.pending}</th>
            <th>{txt.actions}</th>
          </tr>
        </thead>
        <tbody>
          {load && (
            <>
              <tr>
                <td>
                  <div className="d-flex justify-content-center">
                    <div
                      className="spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="d-flex justify-content-center">
                    <div
                      className="spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="d-flex justify-content-center">
                    <div
                      className="spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="d-flex justify-content-center">
                    <div
                      className="spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                </td>
              </tr>
            </>
          )}
          {transacciones &&
            transacciones.map((tran) => {
              const userX = users.filter((usr) => usr.id === tran.customer);

              return (
                <tr key={tran.id}>
                  <td>{tran.id}</td>
                  <td>
                    {userX.length ? (
                      <>
                        {userX[0].id} - {userX[0].name}
                      </>
                    ) : (
                      "Usuario eliminado"
                    )}{" "}
                  </td>
                  <td>{tran.attorney}</td>
                  <td>{tran.transaction_status}</td>
                  <td>$ {formatedNumber(tran.account_balance)} {tran.currency}</td>
                  <td>$ {formatedNumber(tran.pending_obligation)} {tran.currency}</td>
                  <td>
                    <EditTransaction
                      id={tran.id}
                      users={users}
                      customer={tran.customer}
                      clientId={tran.client_id}
                      accountType={tran.account_type}
                      transactionType={tran.transaction_type}
                      transactionId={tran.transaction_id}
                      attorney={tran.attorney}
                      clientAccountNumber={tran.client_account_number}
                      intermedialBank={tran.intermediary_bank}
                      transactionStatus={tran.transaction_status}
                      reinvestStatus={tran.reinbursement_status}
                      accountBalance={tran.account_balance}
                      fundStatus={tran.funds_status}
                      pendientObligations={tran.pending_obligation}
                      disburDate={tran.disinbursement_date}
                      currency={tran.currency}
                    />
                    <DelTransaction id={tran.id} />
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default TransaccionesTable;
