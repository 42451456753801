import {
  faArrowRightArrowLeft,
  faDoorOpen,
  faFilePdf,
  faGauge,
  faGear,
  faGlobe,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getCookie, setCookie } from "../_cookies";
import { ADM_API } from "../_variables";

const Navbar = () => {
  const es = {
    session: "Iniciar sesión",
    lang: "English",
    dash: "Tablero",
    user: "Usuarios",
    trans: "Transacciones",
    conf: "Configuración",
    out: "Cerrar sesión",
    docs: "Documentos",
  };
  const en = {
    session: "Log In",
    lang: "Español",
    dash: "Dashboard",
    user: "Users",
    trans: "Transactions",
    conf: "Settings",
    out: "Log Out",
    docs: "Documents",
  };
  const [txt, setTxt] = useState(es);
  const [session] = useState(getCookie());
  const [admin, setAdmin] = useState(false);

  const isAdmin = () => {
    const adminData = new FormData();
    adminData.append("cookie", session);

    axios
      .post(`${ADM_API}/isAdmin`, adminData)
      .then((res) => {
        if (res.status === 200 && res.data.status === "ok" && res.data.result) {
          setAdmin(res.data.result);
        }
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (localStorage.getItem("lang") === "en") {
      setTxt(en);
    } else {
      setTxt(es);
    }

    isAdmin();
  }, []);

  const changeLang = () => {
    if (localStorage.getItem("lang") === "en") {
      localStorage.setItem("lang", "es");
      window.location.reload();
    } else {
      localStorage.setItem("lang", "en");
      window.location.reload();
    }
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light shadow fixed-top">
      <div className="container">
        <a className="navbar-brand" href="https://main.garcia-ibarra-asociados.com">
          <img
            src="/img/logo.png"
            height="66px"
            width="66px"
            alt="García Ibarra Abogados Logo"
            className="img-fluid"
          />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            {session && admin && (
              <>
                <li className="nav-item">
                  <Link
                    className="nav-link text-dark fw-bold text-center"
                    to="/admin/dashboard"
                  >
                    <FontAwesomeIcon icon={faGauge} size="1x" /> <br />
                    {txt.dash}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link text-dark fw-bold text-center"
                    to="/admin/usuarios"
                  >
                    <FontAwesomeIcon icon={faUser} size="1x" /> <br />
                    {txt.user}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link text-dark fw-bold text-center"
                    to="/admin/transacciones"
                  >
                    <FontAwesomeIcon icon={faArrowRightArrowLeft} size="1x" />{" "}
                    <br />
                    {txt.trans}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link text-dark fw-bold text-center"
                    to="/admin/documentos"
                  >
                    <FontAwesomeIcon icon={faFilePdf} /> <br />
                    {txt.docs}
                  </Link>
                </li>
              </>
            )}
            {session && (
              <li className="nav-item dropdown d-flex justify-content-center">
                <button
                  className="nav-link dropdown-toggle btn btn-link fw-bold text-reset"
                  id="navbarDropdownMenuLink"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <FontAwesomeIcon icon={faGear} /> <br />
                  {txt.conf}
                </button>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <li>
                    <button
                      className="dropdown-item btn btn-link text-reset"
                      onClick={() => {
                        setCookie("userData", "", -1);
                        window.location.reload();
                      }}
                    >
                      <FontAwesomeIcon icon={faDoorOpen} /> {txt.out}
                    </button>
                  </li>
                </ul>
              </li>
            )}
            {!session && (
              <li className="nav-item">
                <Link
                  className="nav-link fw-bold text-dark text-center"
                  to="/login"
                >
                  <FontAwesomeIcon icon={faUser} size="1x" /> <br />
                  {txt.session}
                </Link>
              </li>
            )}
            <li className="nav-item d-flex align-items-center justify-content-center">
              <button
                className="btn btn-link text-decoration-none text-center fw-bold text-reset"
                aria-label={txt.lang}
                onClick={() => changeLang()}
              >
                <FontAwesomeIcon icon={faGlobe} size="1x" /> <br />
                {txt.lang}
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
