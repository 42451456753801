import axios from "axios";
import { useEffect } from "react";
import Layout from "../components/Layout";
import { getCookie } from "../_cookies";
import { ADM_API, API_URL } from "../_variables";

const Test = () => {
  
  const userUploadFile = (e) => {
    const parametros = new FormData(document.getElementById('fileForm'))
    e.preventDefault();

    // const formData = new FormData();
    parametros.append("cookie", getCookie());
    // formData.append("fileToUpload", e.target['fileToUpload'].value);

    axios
      .post(`${API_URL}/upload_my_files`, parametros)
      .then(() => {})
      .catch(() => {});
  };

  const uploadFilesToUser = (e) => {
    const parametros = new FormData(document.getElementById('fileForm'))
    e.preventDefault();

    // const formData = new FormData();
    parametros.append("cookie", getCookie());
    parametros.append("ide", 17);
    // formData.append("fileToUpload", e.target['fileToUpload'].value);

    axios
      .post(`${ADM_API}/upload_files_to_User`, parametros)
      .then(() => {})
      .catch(() => {});
  };

  const userUploadFilesToTransaction = (e) => {
    e.preventDefault();
    const parametros = new FormData(e.target)

    // const formData = new FormData();
    parametros.append("cookie", getCookie());
    parametros.append("tide", 4);
    // formData.append("fileToUpload", e.target['fileToUpload'].value);

    axios
      .post(`${API_URL}/upload_files_to_my_Transaction`, parametros)
      .then(() => {})
      .catch(() => {});
  };
  const uploadFilesToTransaction = (e) => {
    e.preventDefault();
    const parametros = new FormData(e.target)

    // const formData = new FormData();
    parametros.append("cookie", getCookie());
    parametros.append("ide", 4);
    // formData.append("fileToUpload", e.target['fileToUpload'].value);

    axios
      .post(`${ADM_API}/upload_files_to_Transaction`, parametros)
      .then(() => {})
      .catch(() => {});
  };

  useEffect(() => {}, []);

  return (
    <Layout>
      <div className="container py-5">
        <h1>Tests</h1>

        <form action="" onSubmit={(e) => {
          // userUploadFilesToTransaction(e)
          // userUploadFile(e)
          uploadFilesToUser(e)
          // uploadFilesToTransaction(e)
        }} id="fileForm">
          <input
            type="file"
            name="fileToUpload"
            id="fileToUpload"
            className="form-control"
          />
          <button type="submit" className="btn btn-primary">
            Subir archivo
          </button>
        </form>
      </div>
    </Layout>
  );
};

export default Test;
